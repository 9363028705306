// index.get() returns 1,5,10,15,20,etc
var index = function() {

    var first = true;
    var counter = 0;

    function set() {

        counter += 5;

    }

    function get() {

        if ( first ) {
            first = false;
            return 1;
        } else {
            set();
            return counter;
        }

    }

    return {
        get : get
    };

}();

function getJSON(url, callback) {

    fetch(url)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {
            callback( json );
        });

}

/* */
var Global = (function() {

    var YDistance = 750;

    var Intro = React.createClass({

        render: function() {

            return (
                <article className="intro">

                	<div className="grid grid-hm">

                		<div className="intro__inner unit size1of2 h_full" data-0="left: 0px;" data-1500="left: 0px;" data-2000="left: -1500px;">

                			<section className="vert-center">

                				<div className="photo">
                					<img src="/img/chitose.jpg" />
                				</div>

                			</section>

                		</div>

                		<div className="intro__inner unit size1of3 h_full" data-0="left: 0px;" data-1500="left: 0px;" data-2000="left: 1500px;">

                			<section className="vert-center">

                				<h2 className="underline-small f_xxl">sacai</h2>
                				<p className="f_r">Renowned designer Chitose Abe founded the sacai fashion house in Tokyo in 1998. Since then she’s established a signature style defined by fluid reinventions of traditional silhouettes. Abe’s partnership with nike represents her ongoing exploration of the interplay between masculinity and femininity, combined with a reverence for movement.</p>

                			</section>

                		</div>

                		<div className="intro__inner intro__inner-description unit size1of1 h_full" data-0="left: 0px; opacity: 1;" data-2500="left: 0px; opacity: 1;" data-3500="left: 0px; opacity: 0;">

                			<div>
                				<h2 className="underline-big f_xl">NIKELAB PRESENTS NIKE X sacai, A COLLECTION INSPIRED BY THE DESIRE TO INNOVATE FOR THE BODY IN MOTION.</h2>
                				<p className="f_r">By reinterpreting heritage nike sportswear icons, Nike X sacai defines a completely new aesthetic and functionality in sport style for women.</p>
                			</div>

                		</div>

                	</div>

                </article>
            );

        }
    });

    var Door = React.createClass({

        render: function() {

            return (
                <div className="door">

                	<section className="panel panel__left bg-yellow" data-0="left: 0%;" data-500="left: -45%;"></section>
                	<section className="panel panel__right bg-white" data-0="left: 0%;" data-500="left: 100%;"></section>

                	<div className="arm mrxl">
                		<div className="logo-swoosh" data-0="top: -1800px;" data-500="top: 0px;"></div>
                		<a href="javascript:void(0);" className="cross"></a>
                		<div className="logo-sacai" data-0="top: 1800px;" data-500="top: 0px;"></div>
                	</div>

                	<div className="abm">
                		<a id="door-open" className="door-open" href="javascript:void(0);" data-0="opacity: 1; pointer-events: all;" data-250="opacity: 0; pointer-events: none;">OPEN</a>
                	</div>

                </div>
            );

        }
    });

    var PhotoLarge = React.createClass({

        getInitialState: function() {

            return {
                image : '',
                title : '',
                children : '',
                price : ''
            }

        },

        componentWillReceiveProps: function(nextProps) {

            var photo = parseInt(this.props.photo);

            this.setState({
                image : nextProps.data[photo].image,
                title : nextProps.data[photo].title,
                children : nextProps.data[photo].children,
                price : nextProps.data[photo].price
            });

        },

        render: function() {

            var i = parseInt(this.props.index);

            const distances = {
                [`data-${YDistance * (i + 4)}`] : 'left: -1500px;',
                [`data-${YDistance * (i + 5)}`] : 'left: 0px;',
                [`data-${YDistance * (i + 8)}`] : 'left: 0px;',
                [`data-${YDistance * (i + 9)}`] : 'left: -1500px;'
            };

            return (
                <div className="photo" data-0="left: -1500px;" {...distances}>
					<img src={this.state.image} />
					<div className="photo__info bg-white-opaque">
						<h3>NIKELAB <span>X</span> sacai {this.state.title}</h3>
						<hr />
						<p>{this.state.children}</p>
						<h4>£ {this.state.price}</h4>
					</div>
				</div>
            );

        }
    });

    var PhotoSmall = React.createClass({

        getInitialState: function() {

            return {
                image : '',
                title : '',
                children : '',
                price : ''
            }

        },

        componentWillReceiveProps: function(nextProps) {

            var photo = parseInt(this.props.photo);

            this.setState({
                image : nextProps.data[photo].image,
                title : nextProps.data[photo].title,
                children : nextProps.data[photo].children,
                price : nextProps.data[photo].price
            });

        },

        render: function() {

            var i = parseInt(this.props.index);
            var photo = parseInt(this.props.photo);

            var distances = {};

            if ( photo == 2 ) {
                distances = {
                    [`data-${YDistance * (i +  5)}`] : 'left: 1500px;',
                    [`data-${YDistance * (i +  6)}`] : 'left: 0px;',
                    [`data-${YDistance * (i +  8)}`] : 'left: 0px;',
                    [`data-${YDistance * (i +  9)}`] : 'left: 0px;',
                    [`data-${YDistance * (i + 10)}`] : 'left: 1500px;'
                };
            } else if ( photo == 3 ) {
                distances = {
                    [`data-${YDistance * (i +  5)}`] : 'left: 1500px;',
                    [`data-${YDistance * (i +  7)}`] : 'left: 0px;',
                    [`data-${YDistance * (i +  8)}`] : 'left: 0px;',
                    [`data-${YDistance * (i +  9)}`] : 'left: 0px;',
                    [`data-${YDistance * (i + 10)}`] : 'left: 1500px;'
                };
            } else {
                distances = {
                    [`data-${YDistance * (i +  5)}`] : 'left: 1500px;',
                    [`data-${YDistance * (i +  8)}`] : 'left: 0px;',
                    [`data-${YDistance * (i +  8)}`] : 'left: 0px;',
                    [`data-${YDistance * (i +  9)}`] : 'left: 0px;',
                    [`data-${YDistance * (i + 10)}`] : 'left: 1500px;'
                };
            }

            if ( this.state.title ) {
                return (
                    <div className="photo" data-0="left: 1500px;" {...distances}>
                        <img src={this.state.image} />
                        <div className="photo__info bg-white-opaque">
                            <div className="photo__infoinner">
                                <h3>NIKELAB <span>X</span> sacai {this.state.title}</h3>
                                <hr />
                                <p>{this.state.children}</p>
                                <h4>£ {this.state.price}</h4>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="photo" data-0="left: 1500px;" {...distances}>
                        <img src={this.state.image} />
                    </div>
                );
            }

        }
    });

    var PhotosThree = React.createClass({

        getInitialState: function() {

            return {
                data : this.props.data
            }

        },

        componentWillReceiveProps: function(nextProps) {

            this.setState({
                data: nextProps.data
            });

        },

        render: function() {

            var i = index.get();

            return (
                <section className="photos">

                    <div className="grid grid-hm">

                        <div className="unit size1of2 h_full photos__large">

                            <section className="vert-center">

                                <PhotoLarge index={i} photo="0" data={this.state.data} />

                            </section>

                        </div>

                        <div className="unit size1of2 h_full">

                			<section className="grid grid-vm vert-center">

            					<div className="unit size1of1 typo-right">

            						<div className="grid">
            							<div className="unit size3of4">
            								<PhotoSmall index={i} photo="1" data={this.state.data} />
            							</div>
            						</div>

            					</div>

            					<div className="unit size1of1 typo-left">

            						<div className="grid">
            							<div className="unit size1of2">
            								<PhotoSmall index={i} photo="2" data={this.state.data} />
            							</div>
            						</div>

            					</div>

                			</section>

                		</div>

                    </div>

                </section>
            );

        }
    });

    var PhotosFour = React.createClass({

        getInitialState: function() {

            return {
                data : this.props.data
            }

        },

        componentWillReceiveProps: function(nextProps) {

            this.setState({
                data: nextProps.data
            });

        },

        render: function() {

            var i = index.get();

            return (
                <section className="photos">

                    <div className="grid grid-hm">

                        <div className="unit size1of2 h_full photos__large">

                            <section className="vert-center">

                                <PhotoLarge index={i} photo="0" data={this.state.data} />

                            </section>

                        </div>

                        <div className="unit size1of2 h_full">

                			<section className="grid grid-vm vert-center">

            					<div className="unit size1of1 typo-right">

            						<div className="grid">
            							<div className="unit size1of2">
            								<PhotoSmall index={i} photo="1" data={this.state.data} />
            							</div>
            						</div>

            					</div>

            					<div className="unit size1of1 typo-left">

            						<div className="grid">
            							<div className="unit size1of2">
            								<PhotoSmall index={i} photo="2" data={this.state.data} />
            							</div>
            						</div>

            					</div>

            					<div className="unit size1of1 typo-right">

            						<div className="grid">
            							<div className="unit size1of2">
            								<PhotoSmall index={i} photo="3" data={this.state.data} />
            							</div>
            						</div>

            					</div>

                			</section>

                		</div>

                    </div>

                </section>
            );

        }
    });

    var PhotoGroup = React.createClass({

        getInitialState: function() {

            return {
                data : {}
            }

        },

        componentWillReceiveProps: function(nextProps) {

            this.setState({
                data: nextProps.data
            });

        },

        // need some kind of loop here
        render: function() {

            return (
                <div>
                    <PhotosFour data={this.state.data['p01']} />
                    <PhotosFour data={this.state.data['p02']} />
                    <PhotosThree data={this.state.data['p03']} />
                    <PhotosFour data={this.state.data['p04']} />
                    <PhotosThree data={this.state.data['p05']} />
                    <PhotosFour data={this.state.data['p06']} />
                    <PhotosThree data={this.state.data['p07']} />
                    <PhotosFour data={this.state.data['p08']} />
                    <PhotosThree data={this.state.data['p09']} />
                    <PhotosFour data={this.state.data['p10']} />
                    <PhotosFour data={this.state.data['p11']} />
                    <PhotosFour data={this.state.data['p12']} />
                    <PhotosThree data={this.state.data['p13']} />
                    <PhotosFour data={this.state.data['p14']} />
                </div>
            );

        }
    });

    var App = React.createClass({

        getInitialState: function() {

            return {
                data : {}
            }

        },

        componentDidMount: function() {

            var that = this;

            this.serverRequest = getJSON(that.props.source, function(result) {

                that.setState({
                    data: result
                });

            });

        },

        componentWillUnmount: function() {

            this.serverRequest.abort();

        },

        render: function() {

            return (
                <div>
                    <Intro />
                    <Door />
                    <PhotoGroup data={this.state.data} />
                </div>
            );

        }
    });

    ReactDOM.render(
        <App source='/js/data.json' />,
        document.getElementById('main')
    );

})();

var SkrollrInit = (function() {

	function init() {

		scrollToTop();
		openPanels();
		skrollr.init();

	}

	function scrollToTop() {

		setTimeout(function() {
			window.scrollTo(0, 0);
		}, 300);

	}

	function openPanels() {

		document.getElementById('door-open')
				.addEventListener('click', function() {

			setTimeout(function() {
				window.scrollTo(0, 500);
			}, 600);

			setTimeout(function() {
				window.scrollTo(0, 1000);
			}, 900);

		}, false);

	}

	init();

})();
